::-webkit-scrollbar {
  display: none;
  width: 0px;
  height: 0px;
  //background-color: var(--main-background);
  background-color: transparent;
  border: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  width: 1px;
  border: none;
  background-clip: padding-box;
  background-color: var(--accent);
  transition: background-color 2s ease-in-out;
}

::-webkit-scrollbar-button {
  display: none;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  //background-color: darken(var(--accent), 10);
  background-color: var(--accent);
}
