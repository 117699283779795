input[type="range"] {
  outline: 0;
  border: 0;
  border-radius: 14px;
  width: 100%;
  max-width: 100%;
  appearance: none;
  height: 14px;
  background-color: transparent;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  &::-webkit-slider-runnable-track {
    appearance: none;
    height: 14px;
    background-color: transparent;
    border-radius: 0px;
  }
  &::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    cursor: ew-resize;
    background-color: hsl(var(--accent-h), var(--accent-s), calc(var(--accent-l) - 5%));
    box-shadow: var(--box-shadow);

    border-radius: 50%;
    transition: background-color 0.2s ease-in-out;

    // &:active {
    //   background-color: hsl(var(--accent-h), var(--accent-s), calc(var(--accent-l) - 10%));
    //   box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    //   //transform: translate(3px, calc(50% - 3px));
    // }
  }
}
