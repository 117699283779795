progress {
  appearance: none;
  border-radius: 14px;
  width: 100%;
  height: 14px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  &::-webkit-progress-bar {
    background-color: var(--text-icons);
    border-radius: 14px;
  }
  &::-webkit-progress-value {
    background-color: var(--accent);
    border-radius: 14px;
  }
  &:indeterminate {
    &::-webkit-progress-value {
      background-color: var(--accent);
      border-radius: 14px;
      width: 80px;
    }
    &::-webkit-progress-bar {
      background-color: var(--text-icons);
      border-radius: 14px;
    }
  }
}

// progress::-moz-progress-bar {
//   /* style rules */
// }
