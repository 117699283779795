button {
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  text-rendering: auto;
  color: var(--text-icons);
  letter-spacing: 2px;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  padding: 8px;
  border: none;
  border-bottom: 1px solid rgba(var(--accent), 0);
  background-color: transparent;
  transition: all 0.5s;
  outline: none;  
  &:hover {
    border-bottom: 1px solid rgba(var(--accent), 1);
  }
  &:active {
    color: var(--accent);
  }  
}
