/*
 MEDIA QUERY

$breakpoint argument choices:
- xs
- sm
- md
- lg
- xl
*/
$xs: 320px;
$sm: 480px;
$md: 738px;
$lg: 996px;
$xl: 1200px;
$xxl: 1440px;


@mixin breakpoint-size($breakpoint) {
	/* Extra Small Devices, Phones */
	@if $breakpoint == xs {
		@media (max-width: $xs) { @content; }
	}
	/* Small Devices, Phones */
	@else if $breakpoint == sm {
		@media (max-width: $sm) { @content; }
	}
	/* Medium Devices, Tablets */
	@else if $breakpoint == md {
		@media (max-width: $md) { @content; }
	}
	/* Large Devices, Tablets */
	@else if $breakpoint == lg {
		@media (max-width: $lg) { @content; }
	}
	/* Extra Large Devices, Computers */
	@else if $breakpoint == xl {
		@media (min-width: $xl) { @content; }
	}
	@else if $breakpoint == xxl {
		@media (min-width: $xxl) { @content; }
	}
	//only screen and
}

@mixin breakpoint-size-orientation($breakpoint, $orient) {
	/* Extra Small Devices, Phones */
	@if $breakpoint == xs and $orient == landscape {
		@media (max-height: $xs) and (orientation: landscape) { @content; }
	}
	@else if $breakpoint == xs and $orient == portrait {
		@media (max-width: $xs) and (orientation: portrait) { @content; }
	}
	/* Small Devices, Phones */
	@else if $breakpoint == sm and $orient == landscape {
		@media (max-height: $sm) and (orientation: landscape) { @content; }
	}
	@else if $breakpoint == sm and $orient == portrait {
		@media (max-width: $sm) and (orientation: portrait) { @content; }
	}
	/* Medium Devices, Tablets */
	@else if $breakpoint == md and $orient == landscape {
		@media (max-height: $md) and (orientation: landscape) { @content; }
	}
	@else if $breakpoint == md and $orient == portrait {
		@media (max-width: $md) and (orientation: portrait) { @content; }
	}
	/* Large Devices, Tablets */
	@else if $breakpoint == lg and $orient == landscape {
		@media (max-height: $lg) and (orientation: landscape) { @content; }
	}
	@else if $breakpoint == lg and $orient == portrait {
		@media (max-width: $lg) and (orientation: portrait) { @content; }
	}
	/* Extra Large Devices, Computers */
	@else if $breakpoint == xl and $orient == landscape {
		@media (min-height: $xl) and (orientation: landscape) { @content; }
	}
	@else if $breakpoint == xl and $orient == portrait {
		@media (min-width: $xl) and (orientation: portrait) { @content; }
	}

	//only screen and
}

@mixin breakpoint-min-max($min, $max) {
	/* Extra Small Devices, Phones */
	@if $max == xs {
		@media (max-width: $sm) { @content; }
	}
	/* Small Devices, Phones */
	@else if $min == sm and $max == md {
		@media (min-width: $sm) and (max-width: $md) { @content; }
	}
	/* Medium Devices, Tablets */
	@else if $min == md and $max == lg {
		@media (min-width: $md) and (max-width: $lg) { @content; }
	}
	/* Large Devices, Tablets */
	@else if $min == lg and $max == xl {
		@media (min-width: $lg) and (max-width: $xl) { @content; }
	}
	/* Extra Large Devices, Computers */
	@else if $min == xl {
		@media (min-width: $xl) { @content; }
	}

	//only screen and
}

@mixin orient($orient) {
	@media (orientation: $orient) {
		@content;
	}
}