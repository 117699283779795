.horizontal-tab-bar {
  --active-tab-index: 0;
  --active-tab-transform: calc(var(--active-tab-index) * (100% + 20px));

  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  
  button {
    position: relative;
    flex: 1 0 0;
    background-color: var(--list-background-light);
    border-radius: 0px;
    padding: 14px;
    flex-direction: column;
    overflow: visible;
    box-shadow: var(--box-shadow);

    button:active,
    button.active {
      color: var(--text-icons);
      background-color: hsla(var(--accent-h), var(--accent-s), var(--accent-l), 50%);
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
      transform: translate(1px, 1px);
    }

    //&:first-of-type:after {
    //  content: '';
    //  z-index: 1;
    //  background: var(--accent);
    //  transition: 0.25s;
    //  height: 4px;
    //  display: block;
    //  position: absolute;
    //  bottom: -4px;
    //  width: 100%;
    //  transform: translateX(var(--active-tab-transform));
    //}
    
    &.active:after {
      content: '';
      z-index: 1;
      background: var(--accent);
      transition: 0.25s;
      height: 4px;
      display: block;
      position: absolute;
      bottom: -4px;
      width: 100%;
      //transform: translateX(var(--active-tab-transform));
    }

    .hide-indicator button:first-of-type:after {
      display: none;
    }
  }
}
