input[type="color"] {
  opacity: 0;
  width: 32px;
  height: 32px;
  -webkit-appearance: none;

  &::-webkit-color-swatch-wrapper {
    padding: 0;
    border: none;
  }
  &::-webkit-color-swatch {
    border: none;
  }
}
