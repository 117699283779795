.checkbox,
.radiobox {
  display: flex;
  position: relative;
  align-items: center;
  padding: 8px 0px !important;

  .label {
    margin-left: 4px;
    transition: all 0.2s;
  }
  &:hover {
    .label {
      color: var(--accent) !important;
    }
    .checkmark,
    .radiobox {
      background-color: var(--accent);
    }
    border-bottom: 1px solid transparent !important;
  }
}
.radiobox {
  h5 {
    margin-left: 18px !important;
  }
}

/* Create a custom checkbox */
.checkmark,
.radiomark {
  height: 14px;
  width: 14px;
  background-color: var(--text-icons);
  transition: all 0.2s;
}
.checkbox {
  .active {
    background-color: var(--accent);
	color: var(--text-icons);
	border-bottom: 1px solid transparent !important;
    &::after {
      content: "";
      position: absolute;
      left: 4px;
      display: block;
      width: 3px;
      height: 8px;
      border: solid var(--text-icons);
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
}

.checkmark {
  top: 0;
  left: 0;
}

.radiomark {
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.radio-active {
  background-color: var(--accent);
  &::after {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    background-color: var(--text-icons);
    border-radius: 50%;
  }
}
