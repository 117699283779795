$list-width: 300px;

.area-list-page {
  grid-template-columns: $list-width minmax(0, 1fr) !important;
  grid-template-rows: 1fr !important;
  grid-template-areas: "list controls";

  @include breakpoint-size(md) {
    grid-template-columns: 0px minmax(0, 1fr) !important;
  }
  
  .area-list-page-controls {
    grid-area: controls;
  }
}

.page {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0;
  display: grid;
  top: 0;
  left: 101vw;
  grid-template-rows: min-content minmax(0, 1fr);
  grid-template-columns: 100%;
  color: var(--text-icons);
  
  @include breakpoint-size(md) {
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    margin: 8px;
  }
}

.page-navigation {
  position: relative;
  display: flex;
  gap: 40px;

  button {
    position: relative;

    &.active::after {
      content: '';
      z-index: 1;
      background: var(--accent);
      transition: 0.25s;
      left: 0;
      height: 4px;
      display: block;
      position: absolute;
      bottom: -4px;
      width: 100%;
    }
  }
}

.page-crestron-display {
  left: 0;
}


.page-slide-in-from-right {
  animation: page-slide-in-from-right-animation 0.2s linear forwards 1;
}
.page-slide-in-from-left {
  animation: page-slide-in-from-left-animation 0.2s linear forwards 1;
}
.page-slide-out-to-right {
  animation: page-slide-out-to-right-animation 0.2s linear forwards 1;
}
.page-slide-out-to-left {
  animation: page-slide-out-to-left-animation 0.2s linear forwards 1;
}

@keyframes page-slide-in-from-right-animation {
  0% {
    left: 101vh;
  }
  100% {
    left: 0;
  }
}

@keyframes page-slide-in-from-left-animation {
  0% {
    left: -101vh;
  }
  100% {
    left: 0vh;
  }
}

@keyframes page-slide-out-to-right-animation {
  0% {
    left: 0;
  }
  100% {
    left: 101vh;
  }
}

@keyframes page-slide-out-to-left-animation {
  0% {
    left: 0vh;
  }
  100% {
    left: -101vh;
  }
}
