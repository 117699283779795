@import "assets/scss/mixins/mixins";
@import "assets/scss/inputs";
@import "assets/scss/color-definitions";
@import "assets/scss/styles/scrollbar";
@import "assets/scss/page";
@import "assets/fonts/poppins";
@import "assets/fonts/crestron";
//@import "assets/fonts/fontawesome/css/all";

* {
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  font-family: Poppins, serif; //, Roboto, Helvetica, Arial, sans-serif;
}

html,label,input,textarea,h1,h2,h3,h4,h5,span {
  letter-spacing: 2px;
  font-family: Poppins, serif; //, Roboto, Helvetica, Arial, sans-serif;
  font-stretch: 100%;
  font-style: normal;
  font-weight: 400;
}
*:not(input) {
  user-select: none;
}
i {
  font-size: xx-large;
  @include breakpoint-size-orientation(md, landscape) {
    font-size: large;
  }
  @include breakpoint-size(md) {
    font-size: large;
  }
}

html, body { height: 100%; }
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

hr {
  margin: 16px 0;
  border: 1px solid var(--accent);
  @include breakpoint-size(md) {
    margin: 8px;
  }
}

button {
  &:active {
    transform: translate(2px, 2px);
  }
}

app-camera-display {
  position: relative;
  width: 100%;
  height: 100%;
}

app-marquee {
  width: 100%;
}

.fade-in-out {
  color: var(--accent);
  animation: 2.5s infinite cycle-opacity ease-in-out;
}

@keyframes cycle-opacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}