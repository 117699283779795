/* poppins-100 - latin-ext_latin_devanagari */
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 100;
	src: local(""), url(poppins/poppins-v15-latin-ext_latin_devanagari-100.woff2) format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url(poppins/poppins-v15-latin-ext_latin_devanagari-100.woff) format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* poppins-200 - latin-ext_latin_devanagari */
  @font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 200;
	src: local(""), url(poppins/poppins-v15-latin-ext_latin_devanagari-200.woff2) format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url(poppins/poppins-v15-latin-ext_latin_devanagari-200.woff) format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* poppins-100italic - latin-ext_latin_devanagari */
  @font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 100;
	src: local(""), url(poppins/poppins-v15-latin-ext_latin_devanagari-100italic.woff2) format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url(poppins/poppins-v15-latin-ext_latin_devanagari-100italic.woff) format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* poppins-200italic - latin-ext_latin_devanagari */
  @font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 200;
	src: local(""), url(poppins/poppins-v15-latin-ext_latin_devanagari-200italic.woff2) format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url(poppins/poppins-v15-latin-ext_latin_devanagari-200italic.woff) format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* poppins-300 - latin-ext_latin_devanagari */
  @font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 300;
	src: local(""), url(poppins/poppins-v15-latin-ext_latin_devanagari-300.woff2) format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url(poppins/poppins-v15-latin-ext_latin_devanagari-300.woff) format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* poppins-300italic - latin-ext_latin_devanagari */
  @font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 300;
	src: local(""), url(poppins/poppins-v15-latin-ext_latin_devanagari-300italic.woff2) format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url(poppins/poppins-v15-latin-ext_latin_devanagari-300italic.woff) format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* poppins-regular - latin-ext_latin_devanagari */
  @font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	src: local(""), url(poppins/poppins-v15-latin-ext_latin_devanagari-regular.woff2) format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url(poppins/poppins-v15-latin-ext_latin_devanagari-regular.woff) format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* poppins-italic - latin-ext_latin_devanagari */
  @font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 400;
	src: local(""), url(poppins/poppins-v15-latin-ext_latin_devanagari-italic.woff2) format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url(poppins/poppins-v15-latin-ext_latin_devanagari-italic.woff) format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* poppins-500 - latin-ext_latin_devanagari */
  @font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	src: local(""), url(poppins/poppins-v15-latin-ext_latin_devanagari-500.woff2) format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url(poppins/poppins-v15-latin-ext_latin_devanagari-500.woff) format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* poppins-500italic - latin-ext_latin_devanagari */
  @font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 500;
	src: local(""), url(poppins/poppins-v15-latin-ext_latin_devanagari-500italic.woff2) format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url(poppins/poppins-v15-latin-ext_latin_devanagari-500italic.woff) format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* poppins-600 - latin-ext_latin_devanagari */
  @font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	src: local(""), url(poppins/poppins-v15-latin-ext_latin_devanagari-600.woff2) format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url(poppins/poppins-v15-latin-ext_latin_devanagari-600.woff) format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* poppins-600italic - latin-ext_latin_devanagari */
  @font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 600;
	src: local(""), url(poppins/poppins-v15-latin-ext_latin_devanagari-600italic.woff2) format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url(poppins/poppins-v15-latin-ext_latin_devanagari-600italic.woff) format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* poppins-700 - latin-ext_latin_devanagari */
  @font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	src: local(""), url(poppins/poppins-v15-latin-ext_latin_devanagari-700.woff2) format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url(poppins/poppins-v15-latin-ext_latin_devanagari-700.woff) format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* poppins-700italic - latin-ext_latin_devanagari */
  @font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 700;
	src: local(""), url(poppins/poppins-v15-latin-ext_latin_devanagari-700italic.woff2) format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url(poppins/poppins-v15-latin-ext_latin_devanagari-700italic.woff) format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* poppins-800 - latin-ext_latin_devanagari */
  @font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 800;
	src: local(""), url(poppins/poppins-v15-latin-ext_latin_devanagari-800.woff2) format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url(poppins/poppins-v15-latin-ext_latin_devanagari-800.woff) format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* poppins-800italic - latin-ext_latin_devanagari */
  @font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 800;
	src: local(""), url(poppins/poppins-v15-latin-ext_latin_devanagari-800italic.woff2) format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url(poppins/poppins-v15-latin-ext_latin_devanagari-800italic.woff) format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* poppins-900 - latin-ext_latin_devanagari */
  @font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 900;
	src: local(""), url(poppins/poppins-v15-latin-ext_latin_devanagari-900.woff2) format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url(poppins/poppins-v15-latin-ext_latin_devanagari-900.woff) format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* poppins-900italic - latin-ext_latin_devanagari */
  @font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 900;
	src: local(""), url(poppins/poppins-v15-latin-ext_latin_devanagari-900italic.woff2) format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url(poppins/poppins-v15-latin-ext_latin_devanagari-900italic.woff) format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  