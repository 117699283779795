.inputbox {
  margin: 1rem 0 2px 0;
  position: relative;
  width: calc(100% - 16px);
  height: fit-content;
  border-bottom: 2px solid var(--text-icons);
  padding: 8px;
  background-color: var(--main-background);
  border-radius: 7px;

  display: grid;
  grid-template-columns: minmax(0, max-content) 1fr;
  grid-template-areas: "icon input";
  align-items: center;

  i {
    grid-area: icon;
    font-size: small;
    margin-right: 4px;
  }

  textarea,
  input {
    grid-area: input;
    width: 100%;
    border: 0;
    background: none;
    color: var(--text-icons);
    outline: none;
    border-radius: 0;
    &:focus,
    &:valid {
      & ~ label {
        color: var(--accent);
        transform: translate(-8px, -125%);
        font-size: 0.75em;
        cursor: default;
      }
    }
    &:focus {
      & ~ .underline {
        width: 100%;
        border-bottom: 2px solid var(--accent);
      }
      & ~ i {
        color: var(--accent);
      }
    }
  }
  label {
    grid-area: input;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;
    color: var(--text-icons);
    cursor: text;
    transition: all 200ms ease-out;
    margin: 0;
    opacity: 1;
    pointer-events: none;
    font-size: small;
    display: flex;
    align-items: center;
  }
  .underline {
    content: "";
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 100%;
    border-bottom: 2px solid transparent;
    transition: all 200ms ease-out;
    border-radius: 7px;
    pointer-events: none;
  }
}
