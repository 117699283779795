:root {
  --primary-h: 199.5;
  --primary-s: 98.9%;
  --primary-l: 36.7%;
  --primary-a: 90%;
  --primary: hsla(var(--primary-h), var(--primary-s), var(--primary-l), var(--primary-a));

  --accent-h: 169.6;
  --accent-s: 98.9%;
  --accent-l: 36.7%;
  --accent-a: 90%;
  --accent: hsla(var(--accent-h), var(--accent-s), var(--accent-l), var(--accent-a));

  --header-background-h: 0;
  --header-background-s: 0%;
  --header-background-l: 20%;
  --header-background-a: 70%;
  --header-background: hsla(var(--header-background-h), var(--header-background-s), var(--header-background-l), var(--header-background-a));

  --list-background-h: 0;
  --list-background-s: 0%;
  --list-background-l: 23.5%;
  --list-background-a: 70%;
  --list-background: hsla(var(--list-background-h), var(--list-background-s), var(--list-background-l), var(--list-background-a));
  --list-background-light: hsla(var(--list-background-h), var(--list-background-s), calc(var(--list-background-l) + 7%), calc(var(--list-background-a) + 50%));

  --main-background-h: 0;
  --main-background-s: 0%;
  --main-background-l: 28.6%;
  --main-background-a: 70%;
  --main-background: hsla(var(--main-background-h), var(--main-background-s), var(--main-background-l), var(--main-background-a));

  --text-icons-h: 0;
  --text-icons-s: 0%;
  --text-icons-l: 100%;
  --text-icons: hsl(var(--text-icons-h), var(--text-icons-s), var(--text-icons-l));

  --descriptive-text-h: 0;
  --descriptive-text-s: 0%;
  --descriptive-text-l: 80%;
  --descriptive-text: hsl(var(--descriptive-text-h), var(--descriptive-text-s), var(--descriptive-text-l));

  --negative-h: 9;
  --negative-s: 69.4%;
  --negative-l: 56.5%;
  --negative: hsl(var(--negative-h), var(--negative-s), var(--negative-l));

  --positive-h: 112;
  --positive-s: 80%;
  --positive-l: 84.3%;
  --positive: hsl(var(--positive-h), var(--positive-s), var(--positive-l));

  //also red
  --hot-h: 0;
  --hot-s: 91%;
  --hot-l: 68%;
  --hot: hsl(var(--hot-h), var(--hot-s), var(--hot-l));

  //also blue
  --cool-h: 215;
  --cool-s: 77%;
  --cool-l: 76%;
  --cool: hsl(var(--cool-h), var(--cool-s), var(--cool-l));

  --green-h: 151;
  --green-s: 24.1%;
  --green-l: 60.2%;
  --green: hsl(var(--green-h), var(--green-s), var(--green-l));

  --yellow-h: 43;
  --yellow-s: 74.4%;
  --yellow-l: 66.3%;
  --yellow: hsl(var(--yellow-h), var(--yellow-s), var(--yellow-l));

  --box-shadow: -0.7px 0.3px 0.5px rgba(0, 0, 0, 0.3019607843), 1.1px 1.1px 1.7px -0.9px rgba(0, 0, 0, 0.3019607843), 2.8px 2.8px 4.4px -1.8px rgba(0, 0, 0, 0.3019607843), 6.9px 6.9px 10.8px -2.7px rgba(0, 0, 0, 0.3019607843), 1px 1px 0px rgba(255, 255, 255, 0.0509803922) inset;
  --inset-box-shadow: 2px 2px 0px 0px #0000004d inset,-1px -2px 0px 0px #ffffff0d inset;

  //--fa-secondary-color: var(--accent);

  //   --primary: hsl(199.5, 98.9%, 36.7%);
  //   --accent-color: hsl(169.6, 98.9%, 36.7%);
  //   --headers: hsl(0, 0%, 20%);
  //   --list-background: hsl(0, 0%, 23.5%);
  //   --main-background: hsl(0,0,28.6%);
  //   --text-icons: hsl(0,0%,100%);
  //   --descriptive-text: hsl(0,0%,100%);

  //--primary-color: hsl(199.5, 36.7, 50%);
  //--headers: hsl(0, 20%, 0%);
  //--list-background: hsl(0, 23%, 0);
  //--primary: rgb(1, 126, 186);
  //--accent-color: rgb(1, 186, 154);
  //--headers: rgb(51, 51, 51);
  //--list-background: rgb(60, 60, 60);
  //--main-background: rgb(73, 73, 73);
  //--accent-color-clear: rgba(1, 186, 154, 0);
  //--text-icons: rgb(255, 255, 255);
  //--descriptive-text: rgb(255, 255, 255);
  //--negativeColor: rgba(221, 91, 67, 1);
}
