@import "src/assets/scss/mixins/mixins";

.inputCombobox,
.nonInputCombobox {
  margin: 1rem 0 2px 0;
  position: relative;
  width: calc(100% - 2px);
  height: fit-content;
  border-bottom: 1px solid var(--descriptive-text);
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto auto;
  i {
    transition: all 0.2s;
  }
  .fa-times-circle {
    font-size: small;
    margin: auto;
    padding-right: 8px;
  }

  button {
    padding: 0 !important;
  }

  textarea,
  input {
    width: 100%;
    border: 0;
    background: none;
    color: var(--mainText);
    outline: none;
    border-radius: 0;
    font-size: x-large;
    @include breakpoint-size(md) {
      font-size: medium;
    }
    &:focus,
    &:valid {
      & ~ label {
        color: var(--accent);
        transform: translateY(-150%);
        font-size: 0.75em;
        @include breakpoint-size(md) {
          font-size: 0.5em;
        }
        cursor: default;
      }
    }
    &:focus {
      & ~ .underline {
        width: 100%;
      }
    }
  }
  textarea {
    resize: none;
    padding: 0;
    display: block;
    overflow: hidden;
    height: 15px;
    // line-height: normal;
  }
  label {
    position: absolute;
    bottom: 0;
    left: 0;
    color: var(--descriptive-text);
    cursor: text;
    transition: all 200ms ease-out;
    margin: 0;
    opacity: 1;
    pointer-events: none;
    font-size: small;
  }
  .underline {
    content: "";
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 0;
    height: 2px;
    background: var(--accent);
    transition: all 200ms ease-out;
  }
}

.nonInputCombobox {
  .selected-item-label {
    width: 100%;
    border: 0;
    background: none;
    color: var(--mainText);
    outline: none;
    border-radius: 0;
    font-size: x-large;
    @include breakpoint-size(md) {
      font-size: small;
    }
    @include breakpoint-size-orientation(md, landscape) {
      font-size: small;
    }
  }
  .item-valid {
    color: var(--accent);
    transform: translateY(-150%);
    font-size: 0.75em;
    cursor: default;
  }
  .item-focus {
    width: 100%;
  }
  button {
    justify-content: flex-start;
  }
}
